<template>
  <div @click="hideAllDropdown">
    <div>
      <div class="case_result">
        <div class="result">
          <BreadCrumb
            title="Legal AI Trial Copilot"
            subtitle="Case Flow Result"
          />
          <div class="container mt-4" v-if="pageLoading">
            <!-- Uploaded Files : {{ files.length }} <br>
            Completed Percentage :{{ duration }} -->
            <PageLoader
            style="padding-top: 120px;"
            :loading="pageLoading" 
            :completedPercentage="duration"
            :replace_msg="loading_message"  
            />
          </div>
          <div v-else class="container mt-4">
            <div class="card border rounded shadow-sm p-4">
              <h4 class="mb-4 flow">Case Flow Result</h4>
              <!-- Case -->
              <div class="border-btm">
                <div class="case d-flex justify-content-between">
                  <p>Case Info</p>
                  <div class="fact-of-case d-flex justify-content-between">
                    <span class="material-symbols-rounded">download</span>
                    <p v-b-modal.factModal>Facts of the Case</p>
                    <b-modal id="factModal" hide-footer hide-header size="lg">
                      <div class="header mb-4">
                        <p>Facts of case</p>
                        <span
                          class="material-symbols-rounded"
                          @click="$bvModal.hide('factModal')"
                          >close</span
                        >
                        <!-- Pass modal ID as a string -->
                      </div>
                      <div class="pre_event">{{ pre_event }}</div>
                    </b-modal>
                  </div>
                </div>
                <div class="client row mt-3">
                  <div class="col-md-6">
                    <p>Client Type</p>
                    <p class="pt">{{ client_type.charAt(0).toUpperCase() + client_type.slice(1) }}</p>
                  </div>
                  <div class="col-md-6">
                    <p>Case</p>
                    <p class="pt">{{ client_case }}</p>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <p>Jurisdiction</p>
                    <div class="d-flex flex-wrap mr-3">
                      <img
                        :src="jurisdictionFlag.flag"
                        alt="Jurisdiction Flag"
                        class="me-2"
                      />
                      <p class="pt">{{ jurisdictionFlag.name }}</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <p>Court / Institution</p>
                    <p class="pt">
                      {{ getCourt(mainJurisdiction?.name ?? "", mainCourt) }}
                    </p>
                  </div>
                </div>
                <div class="mt-3" v-if="researchJurisdictions.length > 0">
                  <div class="row">
                    <div class="col-md-6">
                      <p>Research Jurisdiction</p>
                    </div>
                    <div class="col-md-6">
                      <p>Court / Institution</p>
                    </div>
                  </div>
                  <div
                    v-for="(jurisdiction, index) in researchJurisdictions"
                    :key="index"
                    class="row mb-3"
                  >
                    <div class="col-md-6">
                      <div class="d-flex flex-wrap mr-3 mb-1">
                        <img
                          :src="getJurisdictionFlag(jurisdiction.name).flag"
                          alt="Jurisdiction Flag"
                          class="me-2"
                        />
                        <p class="pt">
                          {{ getJurisdictionFlag(jurisdiction.name).name }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex flex-wrap">
                        <p
                          class="pt"
                          v-for="(court, idx) in jurisdiction.courts"
                          :key="idx"
                        >
                          {{ getCourt(jurisdiction.name, court) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row for Legal Team and Judiciary Cards -->
                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="team-box p-3">
                      <div class="d-flex justify-content-between">
                        <p>Legal Team ({{ legalTeam.length }})</p>
                        <span
                          class="open_new material-symbols-rounded"
                          v-b-modal.legal-team-modal
                        >
                          open_in_new
                        </span>
                      </div>
                      <span
                        v-for="(member, index) in legalTeam"
                        :key="'legal-' + index"
                        class="person material-symbols-rounded"
                      >
                        person
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="team-box p-3">
                      <div class="d-flex justify-content-between">
                        <p>Judiciary ({{ judiciary.length }})</p>
                        <span
                          class="open_new material-symbols-rounded"
                          v-b-modal.judiciary-modal
                        >
                          open_in_new
                        </span>
                      </div>
                      <span
                        v-for="(judge, index) in judiciary"
                        :key="'judiciary-' + index"
                        class="person material-symbols-rounded"
                      >
                        person
                      </span>
                    </div>
                  </div>
                </div>
                <team-modal
                  v-if="legalTeam.length"
                  modal-id="legal-team-modal"
                  title="Legal Team"
                  :members="legalTeam"
                ></team-modal>
                <team-modal
                  v-if="judiciary.length"
                  modal-id="judiciary-modal"
                  title="Judiciary"
                  :members="judiciary"
                ></team-modal>
              </div>

              <div class="mt-5">
                <div class="case">
                  <p>Generated Files for Your Case</p>
                </div>

                <!-- Phase Tabs -->
                <div class="case d-flex justify-content-between mt-4">
                  <div class="phase-tabs d-flex">
                    <button
                      class="btn btn-phase"
                      :class="{ active: isPreTrialActive }"
                      @click="togglePhase('pre-trial')"
                    >
                      Pre-trial Phase
                    </button>
                    <div
                      v-if="!(trial_data.length === 0 && final_submission)"
                      class="d-flex btn-phase"
                      :class="{ active: isTrialActive }"
                    >
                      <span
                        v-if="isPreTrialActive && trial_data.length === 0"
                        class="material-symbols-rounded lock"
                        >lock</span
                      >
                      <button
                        :class="{
                          disabled: isPreTrialActive && trial_data.length === 0,
                        }"
                        @click="togglePhase('trial')"
                        :disabled="isPreTrialActive && trial_data.length === 0"
                      >
                        Trial Phase
                      </button>
                    </div>
                    <div
                      class="d-flex btn-phase"
                      :class="{ active: isPostTrialActive }"
                    >
                      <span
                        v-if="posttrial_data.length === 0"
                        class="material-symbols-rounded lock"
                        >lock</span
                      >
                      <button
                        :class="{ disabled: posttrial_data.length === 0 }"
                        @click="togglePhase('post-trial')"
                        :disabled="posttrial_data.length === 0"
                      >
                        Post-Trial Phase
                      </button>
                    </div>
                  </div>

                  <!-- Bulk Download -->
                  <div
                    class="fact-of-case d-flex justify-content-between"
                    @click="DownloadBulk('preTrial')"
                  >
                    <span class="material-symbols-rounded">download</span>
                    <p>Bulk Download</p>
                  </div>
                </div>

                <!-- Pre-Trial Phase -->
                <div v-if="isPreTrialActive" class="container mt-4">
                  <div class="row">
                    <div
                      v-for="(item, index) in pretrial_data"
                      :key="index"
                      class="col-md-6 mb-4 doc"
                    >
                      <div class="document-box d-flex">
                        <div class="box">
                          <span class="doc-icon material-symbols-rounded"
                            >description</span
                          >
                          <div class="doc-content">
                            <p class="tit">
                              {{ getKey(item, "Les-") | capitalize }}
                            </p>
                            <p>({{ explanations[index] }})</p>
                          </div>
                        </div>
                        <span
                          class="action-icon material-symbols-rounded"
                          @click="
                            openModal(index, getKey(item, 'Les-'), 'preTrial')
                          "
                          >open_in_new</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- Move to Trial Phase Button -->
                  <div
                    class="d-flex justify-content-end"
                    v-if="trial_data.length === 0 && !final_submission"
                  >
                    <button
                      @click="trialPhase"
                      class="btn trial mt-4 d-flex align-items-center"
                    >
                      <span class="material-symbols-rounded me-2"
                        >arrow_forward</span
                      >Move to Trial Phase
                    </button>
                  </div>
                </div>

                <!-- Trial Phase Form-->
                <div class="container mt-4" v-if="isTrialActive">
                  <!-- Display Trial -->
                  <template v-if="trialCompletion >= 0">
                    <template v-if="trialCompletion >= 100 && !trialdoc_campaign_loader">
                      <div v-if="trial_data.length > 0">
                        <p class="mt-2">First Trial</p>
                        <div class="row">
                          <div
                            v-for="(item, index) in trial_data"
                            :key="index"
                            class="col-md-6 mb-4 doc"
                          >
                            <div class="document-box d-flex">
                              <div class="box">
                                <span class="doc-icon material-symbols-rounded"
                                  >description</span
                                >
                                <div class="doc-content">
                                  <p class="tit">
                                    {{ getKey(item, "LesTrial-") }}
                                  </p>
                                  <p>({{ trial_explanations[index] }})</p>
                                </div>
                              </div>
                              <span
                                class="action-icon material-symbols-rounded"
                                @click="
                                  openModal(
                                    index,
                                    getKey(item, 'LesTrial-'),
                                    'Trial'
                                  )
                                "
                                >open_in_new</span
                              >
                            </div>
                          </div>
                        </div>

                        <!-- Move to Another Trial or End Case -->
                        <div
                          class="fact-of-case d-flex justify-content-between"
                        >
                          <p v-if="trialdoc_data.length || showTrialForm">
                            Ongoing Trial
                          </p>
                        </div>
                        <div class="row mt-2">
                          <div
                            v-for="(item, index) in trialdoc_data"
                            :key="index"
                            class="col-md-6 mb-4 doc"
                          >
                            <div class="document-box d-flex">
                              <div class="box">
                                <span class="doc-icon material-symbols-rounded"
                                  >description</span
                                >
                                <div class="doc-content">
                                  <p class="tit">{{ getKey(item, "_-") }}</p>
                                  <p>({{ trialdoc_explanations[index] }})</p>
                                </div>
                              </div>
                              <span
                                class="action-icon material-symbols-rounded"
                                @click="
                                  openModal(
                                    index,
                                    getKey(item, '_-'),
                                    'OngoingTrial'
                                  )
                                "
                                >open_in_new</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- Trial Actions -->
                        <div
                          class="d-flex justify-content-end"
                          v-if="!final_submission && !showTrialForm"
                        >
                          <button
                            v-if="trialdoc_data.length < 3"
                            class="btn move_trial next me-3 mt-4 d-flex align-items-center"
                            @click="moveTrial"
                          >
                            <span class="material-symbols-rounded me-2"
                              >arrow_forward</span
                            >Move to Another Trial
                          </button>
                          <button @click="endCase" class="btn trial me-3 mt-4">
                            End Case
                          </button>
                        </div>
                      </div>
                      <!-- Ongoing Trial Phase Form -->
                      <div v-if="showTrialForm">
                        <div class="case mt-3">
                          <p>Add New Witness and Evidence (Optional)</p>
                        </div>

                        <!-- Witness Input -->
                        <WitnessInput
                          class="border-btm"
                          @c-input="c_wit = $event"
                          @a-input="a_wit = $event"
                        />

                        <!-- Evidence Input -->
                        <EvidenceInput
                          @c-input="c_evid = $event"
                          @a-input="a_evid = $event"
                        />

                        <!-- Upload Trial Document -->
                        <div class="mt-3">
                          <p class="ut">Upload Trial Document</p>
                          <div class="mt-4">
                            <FileInput
                              v-if="trial_file.length === 0"
                              class="input"
                              :accept="['pdf']"
                              @input-files="files = $event"
                            />
                            <FileProcess
                              class="process"
                              :accept="['pdf']"
                              :files="files"
                              @output-files="handleFileOutput"
                            />
                          </div>
                        </div>

                        <!-- Next Button -->
                        <div class="d-flex justify-content-end">
                          <button
                            v-if="showTrialForm"
                            @click="ongoingCancel"
                            class="btn can me-3 mt-4"
                          >
                            Cancel
                          </button>
                          <button
                            :disabled="!isOngoingFormValid"
                            @click="ongoingNext"
                            :class="{
                              'ogt-disabled': !isOngoingFormValid,
                              ogt: isOngoingFormValid
                            }"
                            class="btn me-3 mt-4 d-flex align-items-center"
                          >
                            <span class="material-symbols-rounded me-2"
                              >arrow_forward</span
                            >Next
                          </button>
                        </div>
                      </div>
                    </template>
                    <div
                      v-else
                      style="
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                        margin-bottom: 20px;
                      "
                    >
                      <b-spinner
                        class="ms-1 me-2"
                        variant="primary"
                        label="Nexa Thinking"
                        style="
                          width: 1.2rem;
                          height: 1.2rem;
                          border-width: 0.125rem;
                        "
                      />
                    </div>
                  </template>
                  <div v-else>
                    <p class="trial_tit">Upload Jury Instruction Document</p>
                    <p class="pt">
                      If applicable, else proceed without the document
                    </p>
                    <div class="mt-4">
                      <FileInput
                        v-if="trial_file.length === 0"
                        class="input"
                        :accept="['pdf']"
                        @input-files="files = $event"
                      />
                      <FileProcess
                        class="process"
                        :accept="['pdf']"
                        :files="files"
                        @output-files="handleFileOutput"
                      />
                    </div>
                    <div class="mt-4">
                      <label for="fileName" class="pt mb-2">File Name</label>
                      <input
                        type="text"
                        v-model="selectedFileName"
                        id="fileName"
                        class="form-control custom_file"
                        placeholder="Please rename your file"
                      />
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        @click="handleFileUpload"
                        class="btn trial mt-4 d-flex align-items-center"
                      >
                        <span class="material-symbols-rounded me-2"
                          >arrow_forward</span
                        >Next
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Post-Trial Phase -->
                <div class="container mt-4" v-if="isPostTrialActive">
                  <div v-if="!final_submission && !postLoading">
                    <p class="mb-4 title">Post-trial Submission</p>
                    <p>Upload Final Trial Document</p>

                    <div class="mt-4">
                      <FileInput
                        v-if="trial_file.length === 0"
                        class="input"
                        :accept="['pdf']"
                        @input-files="postFiles = $event"
                      />
                      <FileProcess
                        class="process"
                        :accept="['pdf']"
                        :files="postFiles"
                        @output-files="handleFileOutput"
                      />
                    </div>
                    <!-- <div class="mt-4">
                      <label for="fileName" class="pt mb-2">File Name</label>
                      <input
                        type="text"
                        v-model="selectedFileName"
                        id="fileName"
                        class="form-control custom_file"
                        placeholder="Please rename your file"
                      />
                    </div> -->
                    <div class="d-flex justify-content-end">
                      <button class="btn cancel me-3 mt-4" @click="postCancel">
                        Cancel
                      </button>
                      <button
                        :disabled="!isPostFormValid"
                        :class="{
                          'next-disabled': !isPostFormValid,
                          next: isPostFormValid,
                        }"
                        class="btn mt-4 d-flex align-items-center"
                        @click="postNext"
                      >
                        <span class="material-symbols-rounded me-2"
                          >arrow_forward</span
                        >Next
                      </button>
                    </div>
                  </div>

                  <!-- Display Post-trial Documents -->
                  <div
                    class="container mt-4"
                    v-if="final_submission && !postLoading"
                  >
                    <div class="row">
                      <div
                        v-for="(item, index) in posttrial_data"
                        :key="index"
                        class="col-md-6 mb-4 doc"
                      >
                        <div class="document-box d-flex">
                          <div class="box">
                            <span class="doc-icon material-symbols-rounded"
                              >description</span
                            >

                            <div class="doc-content">
                              <p class="tit">{{ getKey(item, "Les-") }}</p>
                              <p>({{ posttrial_explanations[index] }})</p>
                            </div>
                          </div>
                          <span
                            class="action-icon material-symbols-rounded"
                            @click="
                              openModal(index, getKey(item, '_-'), 'postTrial')
                            "
                            >open_in_new</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="postLoading"
                    style="
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      align-items: center;
                      justify-content: center;
                      margin-top: 20px;
                      margin-bottom: 20px;
                    "
                  >
                    <b-spinner
                      class="ms-1 me-2"
                      variant="primary"
                      label="Nexa Thinking"
                      style="
                        width: 1.2rem;
                        height: 1.2rem;
                        border-width: 0.125rem;
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <trial-modal
          :showModal.sync="showModal"
          :modalHeader="modalHeader"
          :selectedIndex="selectedIndex"
          :selectedType="selectedType"
          :subtitle="subtitle"
          :viewData="view_data"
          @download="DownloadWords"
        ></trial-modal>
        <!-- LawBot SideBar -->
        <div>
          <LawBot :id="Number(id)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import BreadCrumb from "../../components/misc/BreadCrumb.vue";
import LES from "../../store/LEGACY/LES.js";
import courtData from "../../store/courtname.js";
import country from "../../assets/country/index.js";
import FileInput from "../../components/input/FileInput.vue";
import FileProcess from "../../components/input/FileProcess.vue";
import LawBot from "./LawBot.vue";
import WitnessInput from "./pre_trial/WitnessInput.vue";
import EvidenceInput from "./pre_trial/EvidenceInput.vue";
import { createFormData, validateInput } from "../../store/utils";
import TeamModal from "./result/TeamModal.vue";
import TrialModal from "./result/TrialModal.vue";
import { ExportDataDoc } from "../../store/utils";
// import PageLoader from "./Loader.vue";
import PageLoader from "../../Pages/PageLoader.vue";
import JSZip from "jszip";
export default {
  components: {
    BreadCrumb,
    PageLoader,
    FileProcess,
    FileInput,
    WitnessInput,
    EvidenceInput,
    LawBot,
    TeamModal,
    TrialModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      showModal: false,
      pageLoading: true,
      currentPhase: "pre-trial",
      trialClickCount: 0,
      isCaseEnded: false,
      showTrialForm: false,
      // duration: -1,
      loading_message: "Please wait a moment while we process it.",
      duration: 0,  
      client_case: "",
      client_type: "",
      pre_event: "",
      pre_event_file: "",
      mainJurisdiction: null,
      mainCourt: null,
      researchJurisdictions: [],
      jurisdiction: null,
      pretrial_data: [],
      trial_data: [],
      trialdoc_data: [],
      ongoingTrials: [],
      posttrial_data: [],
      explanations: [],
      trial_explanations: [],
      trialdoc_explanations: [],
      posttrial_explanations: [],
      files: [],
      trial_file: [],
      postFiles: [],
      selectedFileName: "",
      selectedFile: null,
      c_wit: [true, []],
      a_wit: [true, []],
      c_evid: [true, []],
      a_evid: [true, []],
      postLoading: false,
      trial_campaign_loader: false,
      trialdoc_campaign_loader: false,
      posttrial_campaign_loader: false,
      postTrial_doc: null,
      view_data: "",
      modalHeader: "",
      trialCompletion: null,
      subtitle: "",
      selectedIndex: null,
      selectedType: "",
      trialModal: false,
      jurisdictionMap: {
        Canada: { flag: country.CAN, name: "CAN (Canada)" },
        USA: { flag: country.USA, name: "USA (United States of America)" },
        Malaysia: { flag: country.MY, name: "MY (Malaysia)" },
        Singapore: { flag: country.SG, name: "SG (Singapore)" },
        "New Zealand": { flag: country.NZ, name: "NZ (New Zealand)" },
        Australia: { flag: country.AUS, name: "AUS (Australia)" },
        "United Kingdom": { flag: country.UK, name: "UK (United Kingdom)" },
      },
      legalTeam: [],
      judiciary: [],
      interval: null
    };
  },
  created() {
    this.$courtData = courtData;
    LES.GetLES(this.$route.params.id)
      .then((response) => {
        const DATA = response.data.data;
        // console.log(DATA);
      if (DATA.completedPerCentage >= 100) {
        this.duration = 100;
        setTimeout(() => {
          this.pageLoading = false;
        }, 1000);
      }

        this.final_submission = DATA?.final_submission_trial_doc ?? null;

        this.duration = 0;
        if (DATA.completedPerCentage < 100) {
          if (DATA.completedPerCentage >= 0){
            this.duration = DATA.completedPerCentage;
            this.files = DATA.questionDocuments;
          }

          this.interval = setInterval(() => {
            LES.GetLES(this.$route.params.id).then((res) => {
              if (res.data.data.completedPerCentage >= 100) {
                this.duration = res.data.data.completedPerCentage;
                clearInterval(this.interval);
                location.reload();
              } else if (res.data.data.completedPerCentage > 0) {
                this.duration = res.data.data.completedPerCentage;
              }
            });
          }, 10000);
        } else {
          this.duration = 100;
          this.client_case = DATA.client_case;
          this.client_type = DATA.client_type;
          this.pre_event = DATA.pre_event;
          this.jurisdiction = DATA.jurisdiction;
          if (DATA.lawyer) {
            if (Array.isArray(DATA.lawyer)) {
              for (let i = 0; i < DATA.lawyer.length; i++) {
                this.legalTeam[i] = `${DATA.lawyer[i].first_name} ${DATA.lawyer[i].last_name}`;
              }
            } else if (typeof DATA.lawyer === "object") {
              this.legalTeam[0] = `${DATA.lawyer.first_name} ${DATA.lawyer.last_name}`;
            } else if (typeof DATA.lawyer === "string") {
              this.legalTeam[0] = DATA.lawyer;
            }
          }
          if (DATA.judge) {
            if (Array.isArray(DATA.judge)) {
              for (let i = 0; i < DATA.judge.length; i++) {
                this.judiciary[i] = `${DATA.judge[i].first_name} ${DATA.judge[i].last_name}`;
              }
            } else if (typeof DATA.judge === "object") {
              this.judiciary[0] = `${DATA.judge.first_name} ${DATA.judge.last_name}`;
            } else if (typeof DATA.judge === "string") {
              this.judiciary[0] = DATA.judge;
            }
          }
          try {
            const jurisdictionData = JSON.parse(DATA.jurisdiction);
            const jurisdictionKeys = Object.keys(jurisdictionData);
            if (jurisdictionKeys.length > 0) {
              this.mainJurisdiction = {
                name: jurisdictionKeys[0],
                courts: jurisdictionData[jurisdictionKeys[0]],
              };
              this.mainCourt = this.mainJurisdiction.courts[0] || null;
              this.researchJurisdictions = jurisdictionKeys
                .slice(1)
                .map((key) => ({
                  name: key,
                  courts: jurisdictionData[key],
                }));
            } else {
              this.mainJurisdiction = null;
              this.mainCourt = null;
              this.researchJurisdictions = [];
            }
          } catch (error) {
            console.error("Error parsing jurisdiction data:", error);
            this.mainJurisdiction = null;
            this.mainCourt = null;
            this.researchJurisdictions = [];
          }
          // PreTrial
          DATA.lesValues.forEach((element) => {
            this.pretrial_data.push(element);
          });
          this.explanations = this.pretrial_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value;
          });
          // Trial
          this.trialCompletion = DATA.lesTrialCompletedPerCentage;

          DATA.lesTrialValues.forEach((element) => {
            this.trial_data.push(element);
          });
          this.trial_explanations = this.trial_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value;
          });
          // Trial Doc
          this.ongoingTrials = DATA.ongoingTrials || [];

          const startPolling = (response) => {
            const DATA = response.data.data;
            this.ongoingTrials = DATA.ongoingTrials || [];
            const previousLength = this.trialdoc_data.length;
            processOngoingTrials();
            const currentLength = this.trialdoc_data.length;

            this.trialdoc_explanations = this.trialdoc_data.map((item) => {
              const key = Object.keys(item)[0];
              const value = JSON.parse(item[key]).explaination;
              return value;
            });

            if (currentLength <= previousLength) {
              // If not complete, poll for latest data using GetLES
              this.trialdoc_campaign_loader = true;
              setTimeout(() => pollForCompletion(), 10000);
            } else {
              this.trialdoc_campaign_loader = false;
            }
          };

          const pollForCompletion = () => {
            LES.GetLES(this.$route.params.id).then((res) => {
              startPolling(res);
            }).catch((error) => {
              console.error('Failed to retrieve LES data:', error);
            });
          }

          const processOngoingTrials = () => {
            const newTrialdocData = [];
            let resultIndex = 1;

            this.ongoingTrials.forEach((element) => {
              element.results.forEach((result) => {
                const processedResult = {};
                for (const key in result) {
                  processedResult[`_${key}_${resultIndex}`] = result[key];
                }
                newTrialdocData.push(processedResult);
              });
              resultIndex++;
            });
            this.trialdoc_data = newTrialdocData;
          };
          if (DATA.ongoingTrials) {
            for (let i = 0; i < DATA.ongoingTrials.length; i++) {
              if (DATA.ongoingTrials[i].inputs && !DATA.ongoingTrials[i].results.length) {
                startPolling(response);
              }
            }
          }
          if (this.ongoingTrials.length > 0) {
            processOngoingTrials();
          }

          this.trialdoc_explanations = this.trialdoc_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value;
          });

          if (this.final_submission) {
            const ID = this.pretrial_data.findIndex((x) => {
              const KEY = Object.keys(x);
              return (
                KEY.includes("Les-final_brief") ||
                KEY.includes("Les-final_submission")
              );
            });
            // console.log(ID);
            this.posttrial_data.push(this.pretrial_data[ID]);
            this.posttrial_explanations.push(this.explanations[ID]);
            this.pretrial_data.splice(ID, 1);
            this.explanations.splice(ID, 1);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching LES data:", error);
      });
  },
  computed: {
    jurisdictionFlag() {
      return this.getJurisdictionFlag(this.mainJurisdiction?.name);
    },
    trialDocumentsAvailable() {
      return this.trialClickCount < 3;
    },
    isPreTrialActive() {
      return this.currentPhase === "pre-trial";
    },
    isTrialActive() {
      return this.currentPhase === "trial";
    },
    isPostTrialActive() {
      return this.currentPhase === "post-trial";
    },
    isOngoingFormValid() {
      return (this.c_wit && this.c_wit[1].length) ||
        (this.c_evid && this.c_evid[1].length) || 
        (this.a_wit && this.a_wit[1].length) || 
        (this.a_evid && this.a_evid[1].length) || 
        this.trial_file[0];
    },
    isPostFormValid() {
      return this.postFiles.length > 0 && this.selectedFileName.trim()
        ? true
        : false;
    },
  },
  methods: {
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    getKey(obj, prefix) {
      return Object.keys(obj)[0].replace(prefix, "").replace(/_/g, " ");
    },
    getJurisdictionFlag(jurisdictionName) {
      return (
        this.jurisdictionMap[jurisdictionName] || {
          flag: "",
          name: jurisdictionName,
        }
      );
    },
    getCourt(country, courts) {
      if (courts === "all") {
        return `All of ${country} courts`;
      } else {
        const courtData = this.getCourtData(country, courts);
        return courtData ? courtData.name : courts;
      }
    },
    getCourtData(country, courtId) {
      const countryMapping = {
        Malaysia: "MY",
        Australia: "AUS",
        Canada: "CAN",
        "New Zealand": "NZ",
        "United Kingdom": "UK",
        "United States": "US",
        Singapore: "SG",
        USA: "USA"
      };
      const countryAbbreviation = countryMapping[country];
      const courtInfo = this.$courtData[countryAbbreviation];
      if (courtInfo && courtInfo.courts) {
        for (const courtCategory in courtInfo.courts) {
          const courtsArray = courtInfo.courts[courtCategory];
          const court = courtsArray.find((court) => court.id === courtId);
          if (court) {
            return court;
          }
        }
      }
      return null;
    },
    togglePhase(phase) {
      if (
        phase === "pre-trial" ||
        phase === "trial" ||
        phase === "post-trial"
      ) {
        this.currentPhase = phase;
        this.showModal = false;
      }
    },
    trialPhase() {
      this.currentPhase = "trial";
    },
    endCase() {
      this.isCaseEnded = true;
      this.currentPhase = "post-trial";
    },
    showTrialDocuments() {
      if (this.trialClickCount < 3) {
        this.trialClickCount++;
      }
    },
    handleFileOutput(outputFiles) {
      this.trial_file = outputFiles;
      if (this.trial_file.length > 0) {
        this.selectedFileName = this.trial_file[0].name;
      }
    },
    handleFileUpload() {
      const formData = new FormData();
      if (this.trial_file.length > 0) {
        const rawFile = this.trial_file[0].file || this.trial_file[0];
        formData.append("case_file", rawFile);
      }
      if (this.selectedFileName) {
        formData.append("file_name", this.selectedFileName);
      } else {
        formData.append("file_name", null);
      }

      // Initial call to sendFile
      LES.sendFile(this.$route.params.id, formData)
        .then((response) => {
          handleResponse(response);
        })
        .catch((error) => {
          console.error("File upload failed:", error);
        });

      const handleResponse = (response) => {
        const DATA = response.data.data;
        this.trialCompletion = DATA.lesTrialCompletedPerCentage;

        if (this.trialCompletion >= 100) {
          updateData(DATA);
        } else {
          // If not complete, poll for latest data using GetLES
          setTimeout(() => pollForCompletion(), 10000);
        }
      };

      const pollForCompletion = () => {
        LES.GetLES(this.$route.params.id)
          .then((res) => {
            handleResponse(res);
          })
          .catch((error) => {
            console.error("Failed to retrieve LES data:", error);
          });
      };

      const updateData = (DATA) => {
        DATA.lesTrialValues.forEach((element) => {
          this.trial_data.push(element);
        });
        this.trial_explanations = this.trial_data.map((item) => {
          const key = Object.keys(item)[0];
          const value = JSON.parse(item[key]).explaination;
          return value;
        });

        // Trial Doc
        this.ongoingTrials = DATA.ongoingTrials || [];
        const newTrialdocData = [];
        let resultIndex = 1;

        this.ongoingTrials.forEach((element) => {
          element.results.forEach((result) => {
            const processedResult = {};
            for (const key in result) {
              processedResult[`_${key}_${resultIndex}`] = result[key];
            }
            newTrialdocData.push(processedResult);
          });
          resultIndex++;
        });
        this.trialdoc_data = newTrialdocData;
      };
    },
    ongoingNext() {
      const formData = new FormData;

      if (this.trial_file.length > 0) {
        const rawFile = this.trial_file[0].file || this.trial_file[0];
        formData.append('trial_doc', rawFile);
      }

      if (this.c_wit && this.c_wit[1].length) {
        if (this.c_wit[1].every((witness) => typeof witness.name === 'string' && typeof witness.statement === 'string')) {
          const witness_client = this.c_wit[1].map((witness) => JSON.stringify({ [witness.name]: witness.statement }));
          for (let i = 0; i < witness_client.length; i++) {
            formData.append('witness_client[]', witness_client[i]);
          }
        } else {
          const witness_client_file = this.c_wit[1].map((witness) => witness.file);
          for (let i = 0; i < witness_client_file.length; i++) {
            formData.append('witness_client_file[]', witness_client_file[i]);
          }
        }
      }

      if (this.a_wit && this.a_wit[1].length) {
        if (this.a_wit[1].every((witness) => typeof witness.name === 'string' && typeof witness.statement === 'string')) {
          const witness_opposing = this.a_wit[1].map((witness) => JSON.stringify({ [witness.name]: witness.statement }));
          for (let i = 0; i < witness_opposing.length; i++) {
            formData.append('witness_opposing[]', witness_opposing[i]);
          }
        } else {
          const witness_opposing_file = this.a_wit[1].map((witness) => witness.file);
          for (let i = 0; i < witness_opposing_file.length; i++) {
            formData.append('witness_opposing_file[]', witness_opposing_file[i]);
          }
        }
      }

      if (this.c_evid && this.c_evid[1].length) {
        if (this.c_evid[1].every((evidence) => typeof evidence.statement === 'string')) {
          const evidence_client = this.c_evid[1].map((evidence) => evidence.statement);
          for (let i = 0; i < evidence_client.length; i++) {
            formData.append('evidence_client[]', evidence_client[i]);
          }
        } else {
          const evidence_client_file = this.c_evid[1].map((evidence) => evidence.file);
          for (let i = 0; i < evidence_client_file.length; i++) {
            formData.append('evidence_client_file[]', evidence_client_file[i]);
          }
        }
      }

      if (this.a_evid && this.a_evid[1].length) {
        if (this.a_evid[1].every((evidence) => typeof evidence.statement === 'string')) {
          const evidence_opposing = this.a_evid[1].map((evidence) => evidence.statement);
          for (let i = 0; i < evidence_opposing.length; i++) {
            formData.append('evidence_opposing[]', evidence_opposing[i]);
          }
        } else {
          const evidence_opposing_file = this.a_evid[1].map((evidence) => evidence.file);
          for (let i = 0; i < evidence_opposing_file.length; i++) {
            formData.append('evidence_opposing_file[]', evidence_opposing_file[i]);
          }
        }
      }

      for (let pair of formData.entries()) {
        // console.log(pair[0] + ': ' + pair[1]);
      }

      // Initial call to submitOngoing
      LES.submitOngoing(this.$route.params.id, formData).then((response) => {
        handleResponse(response);
      }).catch((error) => {
        // console.log('Submit ongoing failed:', error);
        this.trialdoc_campaign_loader = false;
      });

      const handleResponse = (response) => {
        const DATA = response.data.data;
        this.ongoingTrials = DATA.ongoingTrials || [];
        const previousLength = this.trialdoc_data.length;
        updateData(DATA);
        const currentLength = this.trialdoc_data.length;

        this.trialdoc_explanations = this.trialdoc_data.map((item) => {
          const key = Object.keys(item)[0];
          const value = JSON.parse(item[key]).explaination;
          return value;
        });

        if (currentLength <= previousLength) {
          // If not complete, poll for latest data using GetLES
          this.trialdoc_campaign_loader = true;
          setTimeout(() => pollForCompletion(), 10000);
        } else {
          this.trialdoc_campaign_loader = false;
        }
      };

      const pollForCompletion = () => {
        LES.GetLES(this.$route.params.id).then((res) =>  {
          handleResponse(res);
        }).catch((error) => {
          console.error('Failed to retrieve LES data:', error);
        });
      }

      const updateData = (DATA) => {
        this.ongoingTrials = DATA.ongoingTrials || [];
        const newTrialdocData = [];
        let resultIndex = 1;

        this.ongoingTrials.forEach((element) => {
          element.results.forEach((result) => {
            const processedResult = {};
            for (const key in result) {
              processedResult[`_${key}_${resultIndex}`] = result[key];
            }
            newTrialdocData.push(processedResult);
          });
          resultIndex++;
        });
        this.trialdoc_data = newTrialdocData;
      };
    },
    toastError(flag) {
      this.$toast.error(
        {
          invalid:
            "Please input a valid file name\nWhich should not include: '/' '(' ')' ','",
          empty:
            "Please input a valid file and file name\nWhich should not include: '/' '(' ')' ','",
          file: "Please upload a .pdf file",
        }[flag]
      );
    },
    moveTrial(){
      this.showTrialForm = true;
      this.showModal = false;
    },
    ongoingCancel() {
      this.showModal = false;
      this.showTrialForm = false;
      this.c_wit[1] = [];
      this.c_evid[1] = [];
      this.a_wit[1] = [];
      this.a_evid[1] = [];
      this.files = [];
      this.trial_file = [];
    },
    postCancel() {
      this.postFiles = [];
      this.selectedFileName = "";
      this.showModal = false;
    },
    postNext() {
      const file = validateInput(this.postFiles[0], "file", ["pdf"]);
      this.postLoading = true;

      if (!file[0]) {
        this.toastError(file[1]);
        return;
      }

      // Submission to the server
      LES.finalSubmission(
        this.$route.params.id,
        createFormData({ trial_doc: file[1] })
      )
        .then((res) => {
          this.$toast.success(res.data.message);
          this.pollForFinalSubmission();
        })
        .catch((err) => {
          // console.log(err);
          this.toastError("Failed to upload file. Please try again.");
        });
    },

    pollForFinalSubmission() {
      LES.GetLES(this.$route.params.id)
        .then((res) => {
          const DATA = res.data.data;
          const ID = DATA.lesValues.findIndex((x) => {
            const KEY = Object.keys(x);
            return (
              KEY.includes("Les-final_brief") ||
              KEY.includes("Les-final_submission")
            );
          });
          // console.log(ID);
          // Continue refetching if no valid ID is found
          if (ID === -1) {
            setTimeout(() => this.pollForFinalSubmission(), 10000);
            return;
          }

          // If a valid ID is found
          this.final_submission = DATA?.final_submission_trial_doc ?? null;
          DATA.lesValues.forEach((element) => {
            this.pretrial_data.push(element);
          });

          this.explanations = this.pretrial_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value;
          });
          this.posttrial_data.push(this.pretrial_data[ID]);
          this.posttrial_explanations.push(this.explanations[ID]);

          // Clean up arrays
          this.pretrial_data.splice(ID, 1);
          this.explanations.splice(ID, 1);
          this.postLoading = false;
        })
        .catch((error) => {
          console.error("Failed to retrieve LES data:", error);
          setTimeout(() => this.pollForFinalSubmission(), 10000);
        });
    },

    openModal(index, key, type) {
      this.viewData(index, type);
      this.selectedType = type;
      this.selectedIndex = index;
      this.modalHeader = key;
      this.subtitle = this.getSubtitle(type, index);
      this.showModal = true;
    },
    viewData(index, type) {
      let data;
      switch (type) {
        case 'preTrial':
          data = this.pretrial_data;
          break;
        case 'postTrial':
          data = this.posttrial_data;
          break;
        case 'OngoingTrial':
          data = this.trialdoc_data;
          break;
        default:
          data = this.trial_data;
      }

      for (const [, val] of Object.entries(data[index])) {
        const parsedData = JSON.parse(val);
        this.view_data = parsedData.output;
        // Process text replacements
        this.view_data = this.view_data
          .replaceAll(/\n\s*(\d+)\s/g, "\n$1. ")
          .replaceAll(/\n(\d+)(?![\d.)])/g, "\n$1. ")
          .replaceAll("\n", "<br>")
          .replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>")
          .replaceAll(/\*(.*?)\*/g, "<b>$1</b>")
          .replaceAll(/<a href="/g, '<a target="_blank" href="')
          .replaceAll(/^# (.*?)$/gm, "<b>$1</b>")
          .replaceAll(
            /<br>## (.*?)(<br>|$)/g,
            "<br><b style='font-size:20px;'>$1</b><br>"
          )
          .replaceAll(
            /<br>### (.*?)(<br>|$)/g,
            "<br><b style='font-size:18px;'>$1</b><br>"
          );

        const IMGStyle = `width: auto; height: 400px; border: 1px solid gray;`;
        if (parsedData.cite) {
          this.view_data =
            this.view_data +
            "<b>Evidences Presented:</b><br><br>" +
            Object.entries(parsedData.cite)
              .map((data, id) => {
                return `${id + 1}. <b>${
                  data[0]
                }</b><br><img style="${IMGStyle}" src="data:image/png;base64,${
                  data[1]
                }" alt="${data[0]}" />`;
              })
              .join("<br><br>");
        }
      }
    },
    getSubtitle(type, index) {
      let subtitle;
      switch (type) {
        case 'preTrial':
          subtitle = this.explanations[index];
          break;
        case 'postTrial':
          subtitle = this.posttrial_explanations[index];
          break;
        case 'OngoingTrial':
          subtitle = this.trialdoc_explanations[index];
          break;
        default:
          subtitle = this.trial_explanations[index];
      }

      return subtitle;
    },
    // Individual Download
    DownloadWords(data, category) {
      // console.log(data, category);
      let DATA;
        switch (category) {
          case "preTrial":
            DATA = this.pretrial_data;
            break;
          case "Trial":
            DATA = this.trial_data;
            break;
          case "postTrial":
            DATA = this.posttrial_data;
            break;
          case "OngoingTrial":
            DATA = this.trialdoc_data;
            break;
          default:
            this.$toast.error("Invalid category");
            return;
        }

      for (const [key, value] of Object.entries(DATA[data])) {
        // Apply transformations to the data
        let transformedData = JSON.parse(value).output
            .replaceAll(/\n\s*(\d+)\s/g, "\n$1. ")    
            .replaceAll(/\n(\d+)(?![\d\.)])/g, "\n$1. ") 
            .replaceAll("\n", "<br>")                   
            .replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>")   
            .replaceAll(/\*(.*?)\*/g, "<b>$1</b>")       
            .replaceAll(/<a href="/g, '<a target="_blank" href="') 
            .replaceAll(/^# (.*?)$/gm, "<b>$1</b>")    
            .replaceAll(/<br>## (.*?)(<br>|$)/g, "<br><b style='font-size:20px;'>$1</b><br>") 
            .replaceAll(/<br>### (.*?)(<br>|$)/g, "<br><b style='font-size:16px;'>$1</b><br>") 
            .replaceAll("#", "");             
            
        // console.log(transformedData);
        ExportDataDoc(transformedData);
      }
      this.$toast.success("Successfully downloaded Document");
    },
    // Bulk Download
    async DownloadBulk(type) {
      const ZIP = new JSZip();
      const DATA = type == "preTrial" ? this.pretrial_data : this.trial_data;
      for (const [, raw] of Object.entries(DATA)) {
        for (const [key, val] of Object.entries(raw)) {
          var output = JSON.parse(val);

          var header =
            "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
            "xmlns:w='urn:schemas-microsoft-com:office:word' " +
            "xmlns='http://www.w3.org/TR/REC-html40'>" +
            `<head><meta charset='utf-8'><title>${key}</title></head><meta name="description" content="${output.explaination}"><body>`;
          var footer = "</body></html>";

          var sourceHTML =
            header + output.output.replaceAll("\n", `<br>`) + footer;
          var blob = new Blob([sourceHTML], { type: "application/msword" });
          ZIP.file(`${key.replaceAll("-", "_")}.doc`, blob);
        }
      }
      const blob2 = await ZIP.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob2);
      link.download = "documents.zip";
      link.click();
      link.remove();
    },
  },
};
</script>

<style scoped>
[custom-class="court-modal"] .modal-body {
  padding: 0px;
}
h3,
p {
  margin-bottom: 0px;
}

#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  align-items: center;
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.page-content {
  padding: 0 !important;
}

.case_result {
  display: flex;
  justify-content: space-between;
  .result {
    width: 97%;
    padding: 20px !important;
    .container {
      max-width: 880px;
    }
  }
  .lawbot {
    width: 3%;
    box-shadow: 1px 1px 1px 1px #aaabaf;
  }
  .lawbot_toggle {
    display: flex;
    flex-direction: column;
  }
  .icon {
    background: #0e4485;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    width: 22px;
    color: #ffffff;
  }
}
/* Case */
p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--neutral-black);
}
.pt {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-grey);
}
.case p {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.case .fact-of-case {
  gap: 8px;
  border-radius: 4px;
  padding: 4px 16px;
  cursor: pointer;
}
.case .fact-of-case span {
  width: 16px;
  height: 16px;
  color: var(--primary);
  cursor: pointer;
}

.case .fact-of-case p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--primary) !important;
}
.team-box {
  width: 400px;
  height: 76px;
  border-radius: 8px;
  border: 1px solid var(--mid-grey);
}
.person {
  color: #1890ff !important;
  font-variation-settings: "FILL" 1;
}
.open_new {
  color: var(--primary) !important;
}
.border-btm {
  border-bottom: 1px solid var(--mid-grey);
  padding-bottom: 40px;
}
.flow {
  box-shadow: 0 1px 0px var(--mid-grey);
  padding-bottom: 30px;
  padding-top: 10px;
}
.phase-tabs {
  height: 40px;
  background-color: var(--light-grey);
  padding: 4px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  align-items: center;
}
.btn-phase.active {
  height: 32px;
  padding: 4px 16px;
  gap: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  color: var(--primary);
  border: none;
}
.btn-phase {
  height: 32px;
  padding: 4px 16px;
  gap: 10px;
}
.btn-phase .disabled {
  background-color: transparent;
  border: none;
}

.case_result .btn-phase .lock {
  width: 12px;
  height: 15.75px;
  color: var(--dark-grey);
}
.doc {
  width: 400px;
  height: 66px;
  border-radius: 8px;

  .document-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 400px;
    height: 66px;
    border-radius: 8px;
  }
  .box {
    display: flex;
  }
  .doc-icon {
    width: 40px !important;
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  span {
    color: var(--primary);
    cursor: pointer;
  }
  .doc-content {
    padding-left: 10px;
    .tit {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: var(--neutral-black) !important;
      text-transform: capitalize;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: var(--dark-grey) !important;
    }
  }
}
.trial {
  background: var(--primary);
  color: #ffffff;
}
.move_trial {
  border: 1px solid var(--mid-grey) !important;
  color: #000046 !important;
  background-color: #ffffff !important;
  span {
    color: #000046 !important;
  }
}
.trial_tit p {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.custom_file {
  padding: 8px 12px;
  gap: 10px;
  border-radius: 8px;
}
.ut {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.can {
  padding: 4px 16px;
  border-radius: 4px;
  border: 1px solid var(--mid-grey);
}
.ogt {
  background: var(--primary);
  color: #ffffff;
}
.ogt-disabled {
  background: var(--light-grey);
  color: var(--mid-grey);
  span {
    color: var(--mid-grey);
  }
}
.cancel {
  color: var(--dark-blue) !important;
  border: 1px solid var(--mid-grey);
}
.cancel:hover {
  color: var(--dark-blue);
}
.next {
  background: var(--primary);
  color: #fff;
}

.next-disabled {
  background: var(--light-grey);
  color: var(--mid-grey);
}

.arrow {
  width: 24px;
  height: 24px;
}
.title {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px;
}
</style>
<style scoped>
.team-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: box-shadow 0.2s;
}
.team-box:hover {
  background: var(--interface-grey);
  border: 1px solid var(--mid-grey);
}
.list-group-item {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: none;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 12px;
}
.open_new {
  cursor: pointer;
}
.person {
  color: #888;
}
</style>

<style>
.pre_event {
  max-width: 864px;
  height: 492px;
  border-radius: 8px;
  padding: 12px 24px;
  overflow-y: auto;
  background: var(--interface-grey);
  margin-bottom: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 16px 24px 16px 24px;
  border-bottom: 1px solid #e9ecef;
}

.header p {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  display: inline;
}

.header span {
  cursor: pointer;
}
</style>
